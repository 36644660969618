/* eslint-disable */
/**
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 *
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue'
import {Result,Layout, Input, Tree,Affix,InputNumber, Button, Switch, Radio, Checkbox, Select, Card, Form,Statistic,FormModel,Rate,Row, Col, Modal, Table, Tabs, Icon, Badge, Popover, Dropdown, List, Avatar, Breadcrumb, Steps, Spin, Menu, Drawer, Tooltip, Alert, Tag, Divider, DatePicker, TimePicker, TreeSelect, Upload, Progress, Skeleton, Popconfirm, message, notification,Carousel,Empty,Slider,Collapse,ConfigProvider,Pagination} from 'ant-design-vue'
// import VueCropper from 'vue-cropper'
Vue.use(Result)
Vue.use(Layout)
Vue.use(Input)
Vue.use(Collapse)
Vue.use(FormModel)
Vue.use(InputNumber)
Vue.use(Rate)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Popover)
Vue.use(Affix)
Vue.use(Dropdown)
Vue.use(List)
Vue.use(Slider)
Vue.use(Avatar)
Vue.use(Breadcrumb)
Vue.use(Steps)
Vue.use(Pagination)
Vue.use(Tree)
Vue.use(Spin)
Vue.use(Empty)
Vue.use(Carousel)
Vue.use(Menu)
Vue.use(Statistic)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(TreeSelect)
Vue.use(Progress)
Vue.use(Skeleton)
Vue.use(Popconfirm)
Vue.use(ConfigProvider)
// Vue.use(VueCropper)
Vue.use(notification)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning