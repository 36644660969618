<template>
  <div class="logo">
    <router-link :to="{name:'err'}">
      <img src="/logo.jpg" />
      <h1 v-if="showTitle">{{ title }}</h1>
    </router-link>
  </div>
</template>

<script>
import { i18nRender } from '@/locales'

export default {
  name: 'Logo',
  props: {
    title: {
      type: String,
      default: i18nRender('menu.title'),
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  }
}
</script>
<style>
  .ant-layout-sider .logo{
    background: #4C4C4C !important;
  }
</style>
