// eslint-disable-next-line
import {UserLayout, BasicLayout, RouteView, BlankLayout, PageView} from '@/layouts'

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    children: [
      // dashboard
      {
        path: '/record',
        name: 'record',
        redirect: '/test/record',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.record', keepAlive: true, icon: 'icon-jilu', permission: ['record'] },
        children: [
          {
            path: '/test/record',
            name: 'TestRecord',
            component: resolve => require(['@/views/data/TestRecord'], resolve),
            meta: { title: 'menu.record', keepAlive: true, permission: ['record'] }
          }
        ]
      },
      {
        path: '/driver',
        name: 'driver',
        redirect: '/driver/list',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.driver', keepAlive: true, icon: 'icon-changyonglvyouyewutubiao_fuzhi_sidao', permission: ['driver'] },
        children: [
          {
            path: '/driver/list',
            name: 'DriverList',
            component: resolve => require(['@/views/data/DriverList'], resolve),
            meta: { title: 'menu.driver', keepAlive: true, permission: ['driver'] }
          }
        ]
      },

      {
        path: '*', redirect: '/404', hidden: true
      }
    ],
    meta: { title: '' },
    redirect: '/test/record'
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/u',
    component: UserLayout,
    redirect: '/u/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: resolve => require(['@/views/user/Login'], resolve)
      },
      {
        path: 'register',
        name: 'register',
        component: resolve => require(['@/views/user/Register'], resolve)
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: resolve => require(['@/views/user/RegisterResult'], resolve)
      }
    ]
  },
  {
    path: '/404',
    component: resolve => require(['@/views/exception/404'], resolve)
  }

]
