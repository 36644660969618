import { axios } from '@/utils/request'

export function loadPermissionList (parameter) {
  return axios({
    url: '/share/permission/list',
    method: 'post',
    data: parameter
  })
}

export function exportDataToExcel (parameter) {
  return axios({
    url: '/common/data/export',
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function bindUser (parameter) {
  return axios({
    url: '/share/user/bind',
    method: 'post',
    data: parameter
  })
}

export function loadTestDataList (parameter) {
  return axios({
    url: '/user/test/data/list',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function createMembershipOrder (parameter) {
  return axios({
    url: '/user/pay/order/create',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function captureMembershipOrder (parameter) {
  return axios({
    url: '/user/pay/order/capture',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function checkOrderStatus (parameter) {
  return axios({
    url: '/user/order/status/check',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function resetInfo (parameter) {
  return axios({
    url: '/user/login/pwd/reset',
    method: 'post',
    data: parameter
  })
}

export function resetUserName (parameter) {
  return axios({
    url: '/user/name/reset',
    method: 'post',
    data: parameter
  })
}

export function deleteSharePermission (parameter) {
  return axios({
    url: '/share/permission/del',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoUserSetting (parameter) {
  return axios({
    url: '/keiyo/user/setting/load',
    method: 'post',
    data: parameter
  })
}
