import Vue from 'vue'
import VueStorage from 'vue-ls'
import config from '@/config/defaultSettings'

// base library
// import Viser from 'viser-vue'
// import VueCropper from 'vue-cropper'

// ext library
import VueClipboard from 'vue-clipboard2'
import PermissionHelper from '@/utils/helper/permission'
import './directives/action'
import '@/components/use'

import layer from 'vue-layer'

VueClipboard.config.autoSetContainer = true

// Vue.use(Viser)

Vue.use(VueStorage, config.storageOptions)
Vue.use(VueClipboard)
Vue.use(PermissionHelper)
// Vue.use(VueCropper)

Vue.prototype.$layer = layer(Vue)
